import {
  BadgeHelp,
  BarChartBig,
  Bed,
  BellIcon,
  GlobeIcon,
  NotepadText,
  PackageSearchIcon,
  ShieldAlert,
  Bot,
  MessageCircle,
  MessageSquareMore,
  MessageSquare,
  Handshake,
  Sparkles,
  ChartColumnStacked,
  Zap,
  Smile,
  Rocket,
  Box,
  ShieldPlus,
  Gem
} from 'lucide-react'

export const faqs = [
  {
    question: 'Mennyibe kerül egy LeoAI asszisztens?',
    answer:
      'Az ár attól függ, mekkora a vállalkozásod, céged és mik az igényeid. Kis vállalkozásoknak már akár **havi 10 ezer forintért** üzemeltetjük asszisztensét. Nagyobb vállalkozásoknál ez **40-100 ezer forint** körül mozog havonta. Beszéljük meg mik a céljaid, egyedi igényeid, és adunk egy pontos árajánlatot.'
  },
  {
    question: 'Mi határozza meg az árat?',
    answer:
      'Három fő dolgot nézünk: **1) Mekkora a weboldalad** - minél több oldal és termék van, annál több infót kell megtanulnia a chatbotnak. **2) Hányan látogatják a honlapod havonta** - több látogató több kérdést jelent. **3) Milyen egyedi igényeid vannak**, például beépíthetünk chatbotodba időpont foglaló, webshopoknál termék kereső, csomag követő funkciót.'
  },
  {
    question: 'Mennyi idő alatt lesz kész az AI asszisztensem?',
    answer:
      'Ha kérsz egy demót asszisztenst, **24 órán belül** már tesztelheted is. Pár óra alatt beállítjuk és betanítjuk chatbotodat. Később ha kérsz speciális, egyedi funkciókat az pár napba telhet mire lefejlesztjük őket.'
  },
  {
    question: 'Mi történik, ha az AI nem tud válaszolni egy kérdésre?',
    answer:
      'Ne aggódj, mindenre gondoltunk! Ha a chatbot nem tudna megoldani egy problémát, elkéri az felhasználó email címét vagy telefonszámát, ti pedig rögtön **értesítést kaptok** erről. Ezután könnyedén felveheted a kapcsolatot az ügyféllel a mi vezérlőpultunkon keresztül, vagy hagyományos módon emailben, telefonon.'
  },
  {
    question: 'Mi a helyzet az adatvédelemmel?',
    answer:
      'Asszisztensedet csak a weboldaladon nyilvánosan elérhető adatokból tanítjuk be. Ügyfeleid beszélgetéseit biztonságosan tároljuk, csak te nézheted vissza őket vezérlőpultunkból.'
  },
  {
    question:
      'Mi történik, ha változik a weboldalunk tartalma? Újra kell tanítani az asszisztenst?',
    answer:
      'Ha frissíted a weboldaladat, ne aggódj! Asszisztensed perceken belül újratanítjuk a legfrissebb információkkal, így azonnal **naprakész válaszokat** ad az ügyfeleidnek.'
  },
  {
    question: 'Van próbaidőszak vagy pénzvisszafizetési garancia?',
    answer:
      'Természetesen! **30 napos pénzvisszafizetési garanciát** adunk. Ha nem lennél teljesen megelégedve szolgáltatásunkkal kérdés nélkül visszatérítjük a teljes összeget.'
  },
  {
    question: 'Milyen támogatást nyújtotok az implementálás után?',
    answer:
      'Valamilyen csatornán **folyamatosan tartjuk a kapcsolatot** (Messenger, WhatsApp, Microsoft Teams, Slack, email vagy telefon) és bármi kérdés/kérés van segítünk.'
  },
  {
    question: 'Kaphatok demót vagy tesztverziót, mielőtt döntök?',
    answer:
      'Igen. Most a következő **10 érdeklődőnek ingyen elkészítjük demó chatbotját**. (2024.10.10 - 6 demót már elkészítettünk, már csak 3 maradt)'
  }
]

export const features = [
  {
    icon: BadgeHelp,
    name: 'Áltálnos segítség, eligazítás',
    description: 'Egy központi hely a válaszok nagy részére.'
  },
  {
    icon: ShieldAlert,
    name: 'Szakmai tanácsadás',
    description:
      'ChatGPT töblettudásával segít a szakmai kérdések megválaszolásában is.'
  },
  {
    icon: PackageSearchIcon,
    name: 'Megkeresi hol tart a kért csomagot',
    description: 'Csomagok nyomkövetése sosem volt egyszerűbb.'
  },
  {
    icon: GlobeIcon,
    name: 'A nyelv nem akadály',
    description: 'LeoAI több mint 100+ nyelvet támogat, ami bővül folyamatosan.'
  },
  {
    icon: NotepadText,
    name: 'Értékesít és ismertet',
    description:
      'Termékeket/szolgáltatásokat adja el, növelve a konverzió rátát.'
  },
  {
    icon: BellIcon,
    name: 'Ha nem boldogul egy esettel, értesít titeket',
    description:
      'Lehetőség van üzenetet küldeni, ezzel gyűjtve az emaileket, telefonszámokat.'
  },
  {
    icon: Bed,
    name: '24/7 virtuális asszisztens',
    description:
      'Az AI nem alszik, nem fárad el, nem betegszik meg és nem is megy szabadságra.'
  },
  {
    icon: BarChartBig,
    name: 'Saját vezérlőpult',
    description: 'Kövesd nyomon a chatbot felhasználásának minden lépését.'
  }
]

export const worths = [
  {
    icon: Sparkles,
    title: 'Lenyűgöző első benyomás',
    description:
      'Weboldalad az első kapocs potenciális ügyfeleidhez. Ha kérdéseikre azonnal választ kapnak (Leó pontosan ezt teszi), értékes információhoz jutnak, ami növeli bizalmukat céged iránt.'
  },
  {
    icon: ChartColumnStacked,
    title: 'Megkülönböztető versenyelőny',
    description:
      'Két hasonló cég weboldalát böngészed, felmerül benned egy fontos kérdés. Az egyiknél csak telefonszám van, este 7 van, már nem veszik fel. A másiknál van egy elérhető, barátságos chatbot, aki azonnal válaszol. Melyiktől valószínűbb, hogy vásárolni fogsz?'
  },
  {
    icon: Zap,
    title: 'Gyorsaság fontosabb mint valaha',
    description:
      'A mai ügyfelek a digitális kor gyors tempójához szoktak. Elvárják, hogy problémáikat azonnal megoldják. Minél többet kell várniuk a megoldásra, annál tovább fokozódik frusztrációjuk. Ekkor igazán előnyösek a másodperceken belüli válaszok.'
  },
  {
    icon: Smile,
    title: 'Több visszatérő ügyfél',
    description:
      'A Hubspot kutatása * kimutatta, hogy egy pozitív ügyfélszolgálati élmény után a vásárlók 89%-a nagyobb eséllyel térnek vissza. A visszatérő vásárlók pedig átlagosan 31%-kal költenek többet, mint az újak.'
  }
]

export const ctaTiles = [
  {
    icon: Bot,
    bg: 'pointer-events-none opacity-[0.25] absolute left-1/2 top-1/2 size-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-orange-600 via-rose-600 to-violet-600 opacity-70 blur-[20px]'
  },
  {
    icon: MessageCircle,
    bg: 'pointer-events-none opacity-[0.25] absolute left-1/2 top-1/2 size-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-red-600 to-blue-600 opacity-70 blur-[20px]'
  },
  {
    icon: MessageSquareMore,
    bg: 'pointer-events-none opacity-[0.25] absolute left-1/2 top-1/2 size-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-green-500 via-teal-500 to-emerald-600 opacity-70 blur-[20px]'
  },
  {
    icon: MessageSquare,
    bg: 'pointer-events-none opacity-[0.25] absolute left-1/2 top-1/2 size-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-yellow-400 via-orange-500 to-yellow-600 opacity-70 blur-[20px]'
  },
  {
    icon: BadgeHelp,
    bg: 'pointer-events-none opacity-[0.25] absolute left-1/2 top-1/2 size-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-indigo-500 via-sky-400 to-violet-600 opacity-70 blur-[20px]'
  },
  {
    icon: Handshake,
    bg: 'pointer-events-none opacity-[0.25] absolute left-1/2 top-1/2 size-1/2 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-full bg-gradient-to-r from-gray-600 via-gray-500 to-gray-400 opacity-70 blur-[20px]'
  }
]

export const plans = [
  {
    id: '1',
    icon: Box,
    alt: 'Alap',
    title: 'Alap',
    description: `
        Vállalkozásoknak ajánljuk, ahol a havi látogatók száma nem haladja meg a pár ezret.
        `,
    price: 12990,
    features: [
      'Korlátlan számú üzenet',
      '200 beszélgetés havonta',
      '40 betanítható oldal/dokumentum'
    ]
  },
  {
    id: '2',
    icon: Rocket,
    alt: 'Professzionális',
    title: 'Profi',
    description: `
        Cégeknek, kisebb webshopoknak ajánljuk, ahol a havi látogatók száma nem haladja meg a 10 ezret.
        `,
    price: 23990,
    features: [
      'Korlátlan számú üzenet',
      '500 beszélgetés havonta',
      '100 betanítható oldal/dokumentum'
    ]
  },
  {
    id: 'custom',
    icon: Gem,
    alt: 'Egyedi',
    title: 'Egyedi',
    description:
      'Nem tetszenek a megadott csomagok? Vagy egyedi funkciókra van szükséged? Beszéljünk, és készítünk egy egyedi ajánlatot!',
    price: 'Beszéljük meg!',
    features: [
      'Korlátlan számú üzenet',
      'Korlátlan beszélgetés',
      'Korlátlan adat betanítható',
      'Termékkereső (webshopoknak)',
      'Csomagkövető (webshopoknak)',
      'További egyéni funkciók'
    ]
  }
]
